import React from "react";
import styled from "styled-components";

const Button = React.forwardRef(
  ({ children, onClick, featured, style }, ref) => {
    return featured ? (
      <FeaturedBtn ref={ref} onClick={onClick} style={style}>
        {children}
      </FeaturedBtn>
    ) : (
      <Btn ref={ref} onClick={onClick} featured={featured} style={style}>
        {children}
      </Btn>
    );
  }
);

const FeaturedBtn = styled.button`
  padding: 16px 32px;
  font-weight: 700;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.8);
  color: #ffffff;
  font-size: 0.75rem;
  cursor: pointer;
  letter-spacing: 0.05em;

  :hover {
    background: rgba(0, 0, 0, 0.6);
    border: 2px solid rgba(255, 255, 255, 1);
    transition-delay: 0.1s;
    transition-duration: 0.4s;
  }
`;

const Btn = styled.button`
  padding: 4px 8px;
  font-weight: 500;
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  cursor: pointer;
  text-align: left;
  display: flex;

  span {
    width: 140px;
  }

  :hover,
  :focus {
    background: ${({ theme }) => theme.colors.primary};
    transition-delay: 0.1s;
    transition-duration: 0.4s;
  }

  @media only screen and (max-width: 768px) {
    span {
      width: 80px;
    }
  }
`;

export default Button;
