import { ThemeProvider } from "styled-components";

export const theme = {
  colors: {
    primary: "#2660CA",
    lightPrimary: "#26c5e3",
    lowContrast: "#6E6E6E",
    highContrast: "#e0dede",
    events: "#2C6DD5",
  },
};

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
