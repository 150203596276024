import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { lighten } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { useHoverDirty, useMeasure, useUnmount } from "react-use";
import styled from "styled-components";
import { Z_EPG_HOVER } from "../../../styles/z-index";

const Event = ({
  width,
  left,
  active,
  noFitLeft,
  noFitRight,
  event,
  onItemSelect,
  parentDim,
  isAdult,
}) => {
  const [hover, setHover] = useState(false);
  const refHov = useRef({ current: "" });
  const [ref, { width: contWidth }] = useMeasure();
  const [animateRight, setAnimateRight] = useState(false);
  const isHovering = useHoverDirty(refHov);

  const timerRef = useRef(null);
  const emission_start = new Date(event.emission_start);
  const emission_end = new Date(event.emission_end);
  const eventTitle = isAdult
    ? "Contenido adulto"
    : event.localized?.[0]?.title || event.original_title;

  // Definir mejor dirección de animación en hover
  useEffect(() => {
    setAnimateRight(
      refHov.current.offsetLeft + eventTitle.length * 9 + 24 >
        parentDim.width &&
        parentDim.width - refHov.current.offsetLeft < refHov.current.offsetLeft
    );
  }, [isHovering, eventTitle.length, parentDim.width]);

  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setHover(true);
    }, 300);
  };

  const onMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setHover(false);
  };

  useUnmount(() => {
    clearTimeout(timerRef.current);
  });
  return (
    <>
      <div
        style={{
          width: width,
          height: "52px",
          position: "absolute",
          cursor: event.fake_event || isAdult ? "default" : "pointer",
          boxSizing: "border-box",
          left: left,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={refHov}
        onClick={
          event.fake_event || isAdult ? () => {} : () => onItemSelect(event)
        }
      >
        <EventContainer
          active={active}
          ref={ref}
          className={classNames({ hover })}
          noFitRight={noFitRight}
          noFitLeft={noFitLeft}
        >
          {!hover && <h3>{eventTitle}</h3>}
        </EventContainer>
        <AnimatePresence>
          {hover && (
            <HoverContainer
              $active={active}
              $noFitRight={noFitRight}
              $noFitLeft={noFitLeft}
              $contWidth={contWidth}
              $animateRight={animateRight}
              $parentDim={parentDim}
              $refHov={refHov}
              initial={{
                width: contWidth + (active ? 8 : 10),
                visibility: "hidden",
                right: animateRight ? 0 : "auto",
                left: !animateRight ? 0 : "auto",
              }}
              animate={{
                width: Math.max(
                  contWidth + (active ? 8 : 10),
                  eventTitle.length * 9 + 24,
                  90
                ),
                visibility: "visible",
              }}
              transition={{
                bounce: 0,
                duration: 0.3,
              }}
              exit={{
                width: contWidth + (active ? 8 : 10),
              }}
              className={classNames({ hover })}
            >
              <motion.h3
                initial={{ y: 8 }}
                animate={{ y: 0 }}
                exit={{ y: 8, transition: { duration: 0.2 } }}
                transition={{ delay: 0.2 }}
              >
                {eventTitle}
              </motion.h3>
              <motion.span
                initial={{ y: 8, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 8, opacity: 0, transition: { duration: 0.2 } }}
                transition={{ delay: 0.2 }}
              >
                {emission_start.getHours()}:
                {emission_start.getMinutes() < 10 && 0}
                {emission_start.getMinutes()}- {emission_end.getHours()}:
                {emission_end.getMinutes() < 10 && 0}
                {emission_end.getMinutes()}
              </motion.span>
            </HoverContainer>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

const EventContainer = styled.div`
  background: ${({ active, theme }) =>
    active ? theme.colors.events : "#ffffff"};
  border: ${({ active, theme }) =>
    active ? 0 : `1px solid ${lighten(0.2, theme.colors.events)}`};
  border-top-left-radius: ${({ noFitLeft }) => (noFitLeft ? 0 : "4px")};
  border-top-right-radius: ${({ noFitRight }) => (noFitRight ? 0 : "4px")};
  border-bottom-right-radius: ${({ noFitRight }) => (noFitRight ? 0 : "4px")};
  border-bottom-left-radius: ${({ noFitLeft }) => (noFitLeft ? 0 : "4px")};
  box-sizing: border-box;
  padding: 4px;
  margin: 2px;
  height: 48px;
  text-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &:hover,
  &.hover {
    background: ${({ active, theme }) =>
      active ? lighten(0.05, theme.colors.events) : "#ffffff"};
  }

  & h3 {
    font-size: 18px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ active, theme }) => (active ? "#ffffff" : theme.colors.events)};
  }
  & span {
    font-size: 14px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HoverContainer = styled(motion.div)`
  border: ${({ $active, theme }) =>
    $active ? 0 : `1px solid ${lighten(0.2, theme.colors.events)}`};
  border-top-left-radius: ${({ $noFitLeft }) => ($noFitLeft ? 0 : "4px")};
  border-top-right-radius: ${({ $noFitRight }) => ($noFitRight ? 0 : "4px")};
  border-bottom-right-radius: ${({ $noFitRight }) => ($noFitRight ? 0 : "4px")};
  border-bottom-left-radius: ${({ $noFitLeft }) => ($noFitLeft ? 0 : "4px")};
  padding: 4px;
  margin: 2px;
  box-sizing: border-box;
  text-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  height: 48px;
  z-index: ${Z_EPG_HOVER};

  background: ${({ $active, theme }) =>
    $active ? lighten(0.05, theme.colors.events) : "#ffffff"};

  & h3 {
    font-size: 18px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ $active, theme }) =>
      $active ? "#ffffff" : theme.colors.events};
  }
  & span {
    font-size: 14px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ $active, theme }) =>
      $active ? "#ffffff" : theme.colors.events};
  }

  top: 0;
  min-width: ${({ $contWidth, $active }) => ($contWidth + $active ? 8 : 10)}px;
  padding-left: ${({ $animateRight }) => $animateRight && "16px"};
  box-sizing: border-box;
  max-width: ${({ $animateRight, $parentDim, $refHov }) =>
    !$animateRight
      ? $parentDim.width - $refHov.current.offsetLeft
      : $refHov.current.offsetLeft}px;
`;

export default Event;
