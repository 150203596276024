import { forceHttps } from "./misc";

export const FEATURED = "FEA";
export const FRAME = "FRM";
export const CHANNEL_1 = "NET";
export const CHANNEL_2 = "NT2";

export const TOP_LOCATION = "top";
export const CENTER_LOCATION = "center";

const SIZES = {
  FEA: "1440x810",
  FRM: "700x518",
  NET: "350x259",
  NT2: "350x259",
};

// El campo location refiere a la alineación de la imagen
// si queda recortada, pudiendo valer "top" o "center"
const getImageLink = (link, size, location) => {
  if (link) {
    const protocol = window.location.protocol === "file:" ? "https:" : "";
    return forceHttps(
      link.replace(
        /^(https?:)?\/\//,
        `${protocol}//${process.env.REACT_APP_THUMB_DOMAIN}/getimageurl/${size}/${location}/`
      )
    );
  }
  return "";
};

export const getImage = (obj, imageType, location, presentationMode) => {
  if (!obj.images) return null;
  const image = obj.images_processed[imageType];
  if (image) {
    const size = SIZES[presentationMode || imageType];
    return getImageLink(image.file, size, location);
  } else return null;
};

export const getImageWithoutThumb = (obj, imageType) => {
  if (!obj.images) return null;
  const image = obj.images_processed[imageType];
  if (image) {
    return forceHttps(image.file);
  }
  return null;
};
