import { motion } from "framer-motion";
import styled from "styled-components";
import { Z_EVENT_HOVER, Z_EVENT_INFO } from "../../../styles/z-index";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;

  h3 {
    font-weight: 500;
  }
`;

export const EventsContainer = styled.div`
  margin: 4px 0px 0px -4px;
  position: relative;

  // Estilos del carrousel
  .flickity-viewport {
    overflow: hidden;
  }
  .flickity-button {
    position: absolute;
    height: calc(100% - 2px);
    width: 55px;
    top: 4px;
    background: transparent;
    border: none;
    cursor: pointer;

    :disabled {
      cursor: auto;
      svg {
        opacity: 0.3;
      }
    }
    svg {
      fill: #ffffff;
    }
  }
  .flickity-button-icon {
    width: 60%;
    height: 60%;
  }
  .previous {
    opacity: ${({ prevEnabled }) => {
      return !prevEnabled ? "0" : "1";
    }};
    transition: opacity 0.2s linear;
    top: 0;
    left: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    border-radius: ${({ nextEnabled }) => (nextEnabled ? "12px" : 0)};
  }
  .next {
    opacity: ${({ nextEnabled }) => {
      return !nextEnabled ? "0" : "1";
    }};
    transition: opacity 0.2s linear;
    top: 0;
    right: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  }
`;

export const ItemContainer = styled(motion.div)`
  margin-right: 8px;
  cursor: pointer;
  width: 392px;
  aspect-ratio: 4 / 3;
  border-radius: 12px;
  overflow: hidden;

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 309px;
  }
  @media only screen and (max-width: 768px) and (min-width: 489px) {
    width: 252px;
  }
  @media only screen and (max-width: 489px) {
    width: 209px;
  }

  .event-image {
    object-fit: cover;
    aspect-ratio: 4 / 3;
    width: 100%;
  }

  .provider-image {
    position: absolute;
    width: 60px;
    inset: 8px 8px auto auto;
  }
`;

export const ItemHover = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${Z_EVENT_HOVER};
  top: 0;
  border-radius: 12px;
`;

export const Info = styled.div`
  z-index: ${Z_EVENT_INFO};
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;

  background: linear-gradient(
    180deg,
    transparent 0,
    transparent 1%,
    rgba(0, 0, 0, 0.6) 36%,
    rgba(0, 0, 0, 0.7)
  );

  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
    max-width: 100%;
  }

  .text {
    display: flex;
    flex-direction: column;
    max-width: 90%;

    & h3,
    p {
      color: #ffffff;
      margin: 0;
    }

    & h3 {
      padding: 0;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & p {
      font-weight: 300;
      font-size: 14px;
    }
  }
`;
