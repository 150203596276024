import format from "date-fns/format";
import { es } from "date-fns/locale";

function formatDate(date, formatStr) {
  return format(date, formatStr, {
    locale: es,
  });
}

const FULL_DATE_FORMAT = "dd 'de' MMMM 'a las' HH:mm 'hs'";

export default function formatRelative(from, now) {
  const dif = (from - now) / 1000 / 60;
  const min = Math.round(Math.abs(dif));
  const prefix = dif > 0 ? "En" : "Hace";

  if (min < 2) {
    return "Ahora";
  } else if (min < 60) {
    return `${prefix} ${min}min`;
  } else {
    const limiteMenos1d =
      new Date(now.getFullYear(), now.getMonth(), now.getDate()) - 0;

    if (min < 2 * 60 || (limiteMenos1d < from && from < now)) {
      let h = Math.floor(min / 60);
      let minutes = min - h * 60;
      let m = (Math.floor(minutes / 15) * 15) % 60;
      if (m < 10) {
        m = `0${m}`;
      }
      return `${prefix} ${h}:${m}hs`;
    } else {
      const limite1d =
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) - 0;
      const limite2d =
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2) - 0;
      const limite7d =
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7) - 0;

      if (from < limiteMenos1d) {
        return formatDate(from, FULL_DATE_FORMAT);
      } else if (limiteMenos1d < from && from < limite1d) {
        return `Hoy ${formatDate(from, "HH:mm")}`;
      } else if (from < limite2d) {
        return `Mañana ${formatDate(from, "HH:mm")}`;
      } else if (from < limite7d) {
        const dayDate = formatDate(from, "EEEE HH:mm");
        return dayDate[0].toUpperCase() + dayDate.substr(1);
      }
    }
  }

  return formatDate(from, FULL_DATE_FORMAT);
}
