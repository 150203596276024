import {
  fetchNextEmissions,
  parseCast,
  useEventMetadata,
} from "../../utils/detailUtils";
import imageDefault from "../../utils/img_default_poster.jpg";
import { ArrowDownIcon, CloseIcon } from "../Atoms/Icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faVideo } from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faCalendar,
  faCircle,
} from "@fortawesome/free-regular-svg-icons";
import { theme } from "../../styles/theme";
import { useEffect, useMemo, useState } from "react";
import Loading from "../Atoms/Loading/Loading";
import { motion, useAnimation } from "framer-motion";
import {
  BottomContainer,
  DetailContainer,
  DetailData,
  DetailHeader,
  Info,
  NextEmissions,
  RightContainer,
} from "./styles";
import {
  CHANNEL_1,
  CHANNEL_2,
  FRAME,
  getImage,
  getImageWithoutThumb,
  TOP_LOCATION,
} from "../../utils/thumbnailer";

const Detail = ({ event, metadatas, addMetadatas, onClose }) => {
  const eventMetadata = useEventMetadata({ event, metadatas, addMetadatas });
  const [showNext, setShowNext] = useState(false);
  const [nextEmissions, setNextEmissions] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const controls = useAnimation();

  const hours = Math.floor(eventMetadata?.runtime / 60);
  const minutes = Math.floor(eventMetadata?.runtime % 60);

  const { actors, director } = useMemo(
    () => parseCast({ cast: eventMetadata?.cast, maxActors: 5 }),
    [eventMetadata]
  );

  useEffect(() => {
    if (imageLoaded) {
      controls.start(() => ({
        opacity: 1,
        transition: { duration: 0.5 },
      }));
    }
  }, [imageLoaded, controls]);

  useEffect(() => {
    return () => {
      setShowNext(false);
      setNextEmissions(null);
      setImageLoaded(false);
    };
  }, []);

  return (
    <DetailContainer>
      <span className="close" onClick={onClose}>
        <CloseIcon width={16} height={16} fill="#1a1a1a" />
      </span>
      {!imageLoaded && <Loading />}
      {eventMetadata ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={controls}
          className="container"
        >
          <img
            src={getImage(eventMetadata, FRAME, TOP_LOCATION) || imageDefault}
            className="event-img"
            alt=""
            onLoad={() => setImageLoaded(true)}
          />
          <RightContainer>
            <DetailHeader>
              <h1>
                {eventMetadata.localized[0]?.title ||
                  eventMetadata.original_title}
              </h1>
              <img
                src={
                  getImageWithoutThumb(
                    eventMetadata.video_providers[0],
                    CHANNEL_2
                  ) ||
                  getImageWithoutThumb(
                    eventMetadata.video_providers[0],
                    CHANNEL_1
                  )
                }
                className="provider-img"
                alt={
                  eventMetadata.video_providers[0]?.localized[0]?.title || ""
                }
              />
            </DetailHeader>
            <DetailData>
              <p className="description">
                {eventMetadata.localized[0]?.description ||
                  eventMetadata.description}
              </p>
              <BottomContainer>
                {(eventMetadata.runtime || actors || director) && (
                  <Info>
                    {eventMetadata.runtime && (
                      <span className="info-row">
                        <FontAwesomeIcon icon={faClock} />
                        <span className="text">
                          {hours ? `${hours} h ` : ""}
                          {minutes ? `${minutes} min ` : ""}
                        </span>
                      </span>
                    )}
                    {actors && (
                      <span className="info-row">
                        <FontAwesomeIcon icon={faUsers} />
                        <span className="text">{actors}</span>
                      </span>
                    )}
                    {director && (
                      <span className="info-row">
                        <FontAwesomeIcon icon={faVideo} />
                        <span className="text">{director}</span>
                      </span>
                    )}
                  </Info>
                )}
                <NextEmissions>
                  <span
                    className="emission-header"
                    onClick={() => {
                      fetchNextEmissions({
                        eventTitle: eventMetadata.localized[0]?.title,
                        setNextEmissions: setNextEmissions,
                      });
                      setShowNext((prevState) => !prevState);
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendar} />
                    <span className="text">Próximas emisiones</span>
                    {showNext ? (
                      <span className="dropdown-button">
                        <CloseIcon
                          fill={theme.colors.primary}
                          width={14}
                          height={14}
                        />
                      </span>
                    ) : (
                      <span className="dropdown-button">
                        <ArrowDownIcon
                          fill={theme.colors.primary}
                          width={16}
                          height={16}
                        />
                      </span>
                    )}
                  </span>
                  {showNext &&
                    nextEmissions &&
                    (nextEmissions?.length > 0 ? (
                      nextEmissions.map((emission, idx) => (
                        <span className="emission-row" key={idx}>
                          <span className="circle-wrapper">
                            <FontAwesomeIcon icon={faCircle} size="2xs" />
                          </span>
                          <span className="text">
                            {emission.startDate}, {emission.channel}
                          </span>
                        </span>
                      ))
                    ) : (
                      <span>No se encontraron próximas emisiones.</span>
                    ))}
                </NextEmissions>
              </BottomContainer>
            </DetailData>
          </RightContainer>
        </motion.div>
      ) : (
        <Loading />
      )}
    </DetailContainer>
  );
};

export default Detail;
