import styled from "styled-components";
import { Z_EPG_HEADER, Z_EPG_LINE } from "../../styles/z-index";

export const EPGContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 94%;
  margin: 0 auto;
  margin-bottom: 30px;

  h2 {
    margin: 0;
    font-size: 25px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
  }

  .no-results-found {
    font-weight: 400;
  }
`;

export const EPGHeader = styled.div`
  display: flex;
  width: 100%;
  margin: 10px auto;
  position: sticky;
  top: 66px;
  z-index: ${Z_EPG_HEADER};

  & .timeLineController {
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    cursor: pointer;
  }
`;

export const DropdownContainer = styled.div`
  & button {
    width: 170px;
    position: relative;
    justify-content: left;
    & i {
      position: absolute;
      right: 4px;
    }

    @media only screen and (max-width: 768px) {
      width: 110px;
    }
  }
`;

export const EPGBody = styled.div`
  margin: 0 auto;
  padding: 4px;
  position: relative;
  width: 100%;
`;

export const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 2px;
  top: 0;
  margin-left: -1px;
  background: ${({ theme }) => theme.colors.lightPrimary};
  z-index: ${Z_EPG_LINE};
`;
