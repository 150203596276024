import { useEffect, useRef, useState } from "react";
import { MAX_COUNT_DAYS_BACKWARD } from "../../utils/tvProvidersGridConstants";
import { DropdownDays } from "./DropdownDays";
import Search from "./Search";
import {
  addHours,
  fetchEPGData,
  fetchMatchingEvents,
  selectVideoProviderById,
} from "../../utils/epgUtils";
import { ArrowLeftIcon, ArrowRightIcon } from "../Atoms/Icon/Icon";
import { theme } from "../../styles/theme";
import { TimeLine } from "./Timeline/TimeLine";
import { useInterval } from "react-use";
import { AutoSizer, List, WindowScroller } from "react-virtualized";
import VideoProviderRow from "./VideoProviderRow";
import {
  DropdownContainer,
  EPGBody,
  EPGContainer,
  EPGHeader,
  Line,
} from "./styles";
import SearchedEvents from "./SearchedEvents/SearchedEvents";

const EPG = ({ openDetail, setFetchedEvents }) => {
  const now = new Date();
  if (now.getMinutes() < 15) {
    now.setHours(now.getHours(), 0, 0);
  } else if (now.getMinutes() >= 15 && now.getMinutes() < 45) {
    now.setHours(now.getHours(), 30, 0);
  } else {
    now.setHours(now.getHours() + 1, 0, 0);
  }

  const [videoProvidersIds, setVideoProvidersIds] = useState([]);
  const [videoProvidersData, setVideoProvidersData] = useState([]);
  const [events, setEvents] = useState({});
  const [cantHours, setCantHours] = useState(4);
  const [timeLineWidth, setTimeLineWidth] = useState(0);
  const [search, setSearch] = useState("");
  const [left, setLeft] = useState(0);
  const [center, setCenter] = useState(true);
  const [currentDay, setCurrentDay] = useState(new Date());
  const [videoProvidersVisibles, setVideoProvidersVisibles] = useState([]);
  // Inicializa la ventana según la cantidad de horas por defecto
  const [window, setWindow] = useState({
    windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
    windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
  });
  const [fetchedBlocks, setFetchedBlocks] = useState([]);
  const [prevSearch, setPrevSearch] = useState(null);
  const [prevRequestController, setPrevRequestController] = useState(null);
  const [searchedEvents, setSearchedEvents] = useState(null);

  const headerRef = useRef();
  const bodyRef = useRef();
  let windowScrollerRef = useRef();

  const updateWindow = (newWindow) => {
    setCenter(false);
    setWindow(newWindow);
    const newDate = new Date(newWindow.windowEnd);
    const prevDate = new Date(currentDay);
    if (newDate.getDate() !== prevDate.getDate()) {
      setCurrentDay(newWindow.windowEnd);
    }
  };

  const handleDropdownDayClick = (idx) => {
    const day = idx - MAX_COUNT_DAYS_BACKWARD;
    const selectedMoment = new Date();
    if (selectedMoment.getMinutes() > 30) {
      selectedMoment.setHours(0, 30, 0);
    } else {
      selectedMoment.setHours(0, 0, 0);
    }
    selectedMoment.setDate(selectedMoment.getDate() + day);
    setCenter(false);
    updateWindow({
      windowStart: addHours(new Date(selectedMoment), -0.25),
      windowEnd: addHours(new Date(selectedMoment), cantHours + 0.25),
    });
  };

  const handleArrowLeftClick = () => {
    setCenter(false);
    updateWindow({
      windowStart: addHours(new Date(window.windowStart), -0.5),
      windowEnd: addHours(new Date(window.windowEnd), -0.5),
    });
  };

  const handleArrowRightClick = () => {
    setCenter(false);
    updateWindow({
      windowStart: addHours(new Date(window.windowStart), 0.5),
      windowEnd: addHours(new Date(window.windowEnd), 0.5),
    });
  };

  const centerWindow = () => {
    const now = new Date();
    if (now.getMinutes() < 15) {
      now.setHours(now.getHours(), 0, 0);
    } else if (now.getMinutes() >= 15 && now.getMinutes() < 45) {
      now.setHours(now.getHours(), 30, 0);
    } else {
      now.setHours(now.getHours() + 1, 0, 0);
    }
    const windowStart = addHours(now, -Math.floor(cantHours / 2) - 0.25);
    const windowEnd = addHours(now, Math.round(cantHours / 2) + 0.25);
    setWindow({
      windowStart,
      windowEnd,
    });
    setCenter(true);
    setCurrentDay(windowEnd);
  };

  const _setRef = (ref) => {
    windowScrollerRef = ref;
  };

  const changeSearchValue = (e) => {
    setSearch(e.target.value);
  };

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    return (
      <div key={key} style={style}>
        <VideoProviderRow
          window={window}
          lineWidth={timeLineWidth}
          videoProvider={selectVideoProviderById({
            videoProviders: videoProvidersData,
            id: videoProvidersVisibles[index],
          })}
          events={events[videoProvidersVisibles[index]]}
          onItemSelect={openDetail}
        />
      </div>
    );
  };

  // Hack para que se actualice la VideoProviderRow cuando cambian
  // los eventos. ¿Por qué sin esto no se actualiza? ((⇀‸↼))
  useEffect(() => {
    setWindow((prevWindow) => ({ ...prevWindow }));
  }, [events]);

  // Filtra según el valor de búsqueda
  useEffect(() => {
    const filterVP = () => {
      if (search === "") {
        return videoProvidersIds;
      } else {
        return videoProvidersIds.filter((videoProviderId) => {
          const item = videoProvidersData.find(
            (videoProvider) => videoProvider.service_id === videoProviderId
          );
          return (
            (item.localized[0]?.title || item.name)
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.service_id?.toString().includes(search)
          );
        });
      }
    };

    const searchEvents = () => {
      if (search && search !== prevSearch) {
        if (prevRequestController) {
          prevRequestController.abort("Search input changed.");
        }
        const requestController = new AbortController();
        const requestSignal = requestController.signal;
        fetchMatchingEvents({
          search,
          signal: requestSignal,
          setSearchedEvents,
        });
        setPrevSearch(search);
        setPrevRequestController(requestController);
      }
    };

    setVideoProvidersVisibles(filterVP());
    setSearchedEvents(null);
    searchEvents();
  }, [
    search,
    videoProvidersIds,
    videoProvidersData,
    prevRequestController,
    prevSearch,
  ]);

  // Actualiza los video_providers visibles cuando cambia la lista
  // de todos los video_providers (debería pasar solo al principio)
  useEffect(
    () => setVideoProvidersVisibles(videoProvidersIds),
    [videoProvidersIds]
  );

  // Trae los video_providers y sus eventos
  useEffect(() => {
    const fetchEvents = async () => {
      fetchEPGData({
        dateFrom: new Date(window.windowStart),
        dateTo: new Date(window.windowEnd),
        fetchedBlocks,
        setFetchedBlocks,
        setEvents,
        setVideoProvidersData,
        setVideoProvidersIds,
      });
    };

    fetchEvents();
    setFetchedEvents();
  }, [window.windowStart, window.windowEnd, fetchedBlocks, setFetchedEvents]);

  // Actualiza la ventana cuando cambia la cantidad de horas visibles
  useEffect(() => {
    const now = new Date();
    if (now.getMinutes() < 15) {
      now.setHours(now.getHours(), 0, 0);
    } else if (now.getMinutes() >= 15 && now.getMinutes() < 45) {
      now.setHours(now.getHours(), 30, 0);
    } else {
      now.setHours(now.getHours() + 1, 0, 0);
    }
    setWindow({
      windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
      windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
    });
  }, [cantHours]);

  // Define la posición de la linea de epg (left)
  useEffect(() => {
    const windowStart = new Date(window.windowStart);
    const windowEnd = new Date(window.windowEnd);
    const currentDate = new Date();

    const windowSize = windowEnd.getTime() - windowStart.getTime();
    setLeft(
      ((currentDate.getTime() - windowStart.getTime()) * 100) / windowSize - 1
    );
  }, [window.windowStart, window.windowEnd]);

  // Actualiza la posición de WindowScroller cuando aparece el componente Destacados
  useEffect(() => {
    // Solo llamar updatePosition si la ref ya fue seteada por _setRef()
    if (windowScrollerRef?.state) {
      windowScrollerRef?.updatePosition();
    }
  }, [bodyRef.current?.offsetTop]);

  // Actualiza la ventana cada 30 segundos
  useInterval(() => {
    if (center) {
      const now = new Date();
      if (now.getMinutes() < 15) {
        now.setHours(now.getHours(), 0, 0);
      } else if (now.getMinutes() >= 15 && now.getMinutes() < 45) {
        now.setHours(now.getHours(), 30, 0);
      } else {
        now.setHours(now.getHours() + 1, 0, 0);
      }
      setWindow({
        windowStart: addHours(now, -Math.floor(cantHours / 2) - 0.25),
        windowEnd: addHours(now, Math.round(cantHours / 2) + 0.25),
      });
    }
  }, 30000);

  useInterval(() => {
    const windowStart = new Date(window.windowStart);
    const windowEnd = new Date(window.windowEnd);
    const currentDate = new Date();

    const windowSize = windowEnd.getTime() - windowStart.getTime();
    setLeft(
      ((currentDate.getTime() - windowStart.getTime()) * 100) / windowSize - 1
    );
  }, 1000);

  return Object.keys(events).length > 0 ? (
    <EPGContainer>
      <Search onChange={changeSearchValue} />
      <SearchedEvents
        events={searchedEvents}
        search={search}
        onItemSelect={openDetail}
      />
      <h2>Canales</h2>
      <EPGHeader ref={headerRef}>
        <DropdownContainer>
          <DropdownDays
            currentDay={currentDay}
            onDayClick={handleDropdownDayClick}
          />
        </DropdownContainer>
        <span className="timeLineController" onClick={handleArrowLeftClick}>
          <ArrowLeftIcon fill={theme.colors.primary} />
        </span>
        <TimeLine
          window={window}
          setWindow={updateWindow}
          setCantHours={setCantHours}
          setWidth={setTimeLineWidth}
          left={left}
          centerWindow={centerWindow}
        />
        <span className="timeLineController" onClick={handleArrowRightClick}>
          <ArrowRightIcon fill={theme.colors.primary} />
        </span>
      </EPGHeader>
      <EPGBody ref={bodyRef}>
        {videoProvidersVisibles?.length > 0 && (
          <>
            <EPGLine window={window} left={left} width={timeLineWidth} />
            <WindowScroller ref={_setRef}>
              {({ height, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      autoHeight
                      height={height}
                      width={width}
                      scrollTop={scrollTop}
                      rowCount={videoProvidersVisibles.length}
                      rowHeight={54}
                      rowRenderer={rowRenderer}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          </>
        )}
      </EPGBody>
    </EPGContainer>
  ) : null;
};

const EPGLine = ({ width, left }) => {
  return left < 0 || left > 100 ? null : (
    <div
      style={{
        width: width,
        position: "absolute",
        right: 24,
        height: "100%",
      }}
    >
      <Line style={{ left: `${left}%` }} />
    </div>
  );
};

export default EPG;
