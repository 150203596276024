import React from "react";
import { useLayer, Arrow } from "react-laag";
import { AnimatePresence } from "framer-motion";
import { useMount } from "react-use";

import styled from "styled-components";

const ArrowCont = styled.div`
  & svg path {
    fill: ${({ theme }) => theme.colors.highContrast};
  }
`;

const Dropdown = ({
  isOpen,
  children,
  setTriggerProps,
  Container,
  closeTrigger,
  onMouseOver,
  onMouseLeave,
  width,
}) => {
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: closeTrigger, // close the menu when the user clicks outside
    onDisappear: closeTrigger, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // to avoid warning, menu is kept inside the container by Container's overflow-y
    auto: true, // automatically find the best placement
    placement: "bottom-end", // we prefer to place the menu "top-end"
    possiblePlacements: ["bottom-end", "right-start"],
  });

  useMount(() => {
    setTriggerProps(triggerProps);
  });

  return renderLayer(
    <AnimatePresence initial={false}>
      {isOpen && (
        <Container
          initial={{
            opacity: 0,
            y: "-10%",
            width: width,
            boxSizing: "border-box",
          }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{
            opacity: 0,
          }}
          transition={{
            delay: 0.15,
            type: "spring",
            damping: 30,
            stiffness: 500,
          }}
          {...layerProps}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          className="dropdownUL"
        >
          <div onClick={closeTrigger} style={{ overflowX: "hidden" }}>
            {children}
          </div>
          <ArrowCont>
            <Arrow {...arrowProps} size={10} roundness={0} />
          </ArrowCont>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default Dropdown;
