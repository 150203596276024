import styled from "styled-components";
import {
  Z_LOADING_CONTAINER,
  Z_LOADING_ROTATIVE,
} from "../../../styles/z-index";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  margin: ${({ fullscreen }) => (fullscreen ? "auto" : "-20px 0 0 -20px")};
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  z-index: ${Z_LOADING_CONTAINER};

  & p {
    font-weight: bold;
    padding-top: 100px;
  }

  ${({ propsStyle }) => propsStyle};
`;

export const Rotative = styled.div`
  & .spinner {
    animation: rotate 2s linear infinite;
    z-index: ${Z_LOADING_ROTATIVE};
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    fill: #ddd;

    & .path {
      stroke: ${(props) => props.theme.colors.primary};
      stroke-width: 3;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
