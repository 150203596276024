import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Container, Rotative } from "./styles";

const DELAY_TIMER_TTL = 0;

export const Loading = ({ delayTimerTTL, delayText, fullscreen, style }) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowText(true);
    }, delayTimerTTL || DELAY_TIMER_TTL);

    return () => {
      clearTimeout(timer);
    };
  }, [delayTimerTTL]);

  return (
    <AnimatePresence className="app-loading">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Container fullscreen={fullscreen} propsStyle={style}>
          <Rotative>
            <svg className="spinner" width="50" height="50" viewBox="0 0 50 50">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          </Rotative>
          {showText && <p>{delayText || null}</p>}
        </Container>
      </motion.div>
    </AnimatePresence>
  );
};

export default Loading;
