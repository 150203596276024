import styled from "styled-components";
import { theme } from "../../styles/theme";
import { SearchIcon } from "../Atoms/Icon/Icon";

const Search = ({ onChange }) => (
  <SearchContainer>
    <IconContainer>
      <SearchIcon fill={theme.colors.primary} width={20} height={20} />
    </IconContainer>
    <SearchField placeholder="Buscar..." onChange={onChange} />
  </SearchContainer>
);

const IconContainer = styled.div`
  position: absolute;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

const SearchContainer = styled.div`
  width: 100%;
  margin: 30px auto;
`;

const SearchField = styled.input`
  width: 100%;
  margin: 0;
  padding: 6px 42px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};

  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  :focus {
    outline: 0.5px solid ${({ theme }) => theme.colors.primary};
  }
`;

export default Search;
