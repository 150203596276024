const CURRENT_EVENT = 0;

const fetchTVProvidersEvents = async ({ tvProviders }) => {
  const eventsList = [];
  for (const item of tvProviders) {
    await fetch(
      `${process.env.REACT_APP_API_URL}/asset/${item.events[CURRENT_EVENT].id}/?cable_operator=1&format=json`
    )
      .then((response) => response.json())
      .then((data) => data.images_processed["FRM"] && eventsList.push(data));
  }
  return eventsList;
};

export const fetchFeaturedData = async () => {
  let rawFeaturedList = [];
  //   Opción 1: Usar la tira de eventos destacados manual
  await fetch(
    `${process.env.REACT_APP_API_URL}/navigation_filter/${process.env.REACT_APP_MANUALLY_FEATURED}/filter/?cable_operator=1&format=json`
  )
    .then((response) => response.json())
    .then(async (data) => {
      if (data.results.length > 0) {
        rawFeaturedList = data.results;
        // Si no hay eventos destacados manualmente (o ya terminaron)
      } else {
        //   Opción 2: Usar los eventos actuales de los TV providers que están en la tira "Lo que se está viendo"
        await fetch(
          `${process.env.REACT_APP_API_URL}/navigation_filter/${process.env.REACT_APP_MOST_SEEN_EVENTS}/filter/?cable_operator=1&format=json`
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.results.length > 0) {
              rawFeaturedList = await fetchTVProvidersEvents({
                tvProviders: data.results,
              });
              //   Si la tira "Lo que se está viendo" no está funcionando
            } else {
              //   Opción 3: Usar los eventos actuales de los TV providers que están en la tira por defecto (fallback)
              await fetch(
                `${process.env.REACT_APP_API_URL}/navigation_filter/${process.env.REACT_APP_FALLBACK_EVENTS}/filter/?cable_operator=1&format=json`
              )
                .then((response) => response.json())
                .then(async (data) => {
                  rawFeaturedList = await fetchTVProvidersEvents({
                    tvProviders: data.results,
                  });
                });
            }
          });
      }
    })
    .catch(() => (rawFeaturedList = []));

  return rawFeaturedList;
};

export const fetchEventTime = async ({ event }) => {
  let emissionStart = null;
  let emissionEnd = null;
  await fetch(
    `${process.env.REACT_APP_ZPAPI_URL}/offer/${event.offer_id}/?format=json`
  )
    .then((response) => response.json())
    .then((data) => {
      emissionStart = new Date(data.emission_start);
      emissionEnd = new Date(data.emission_end);
    });
  return { emissionStart, emissionEnd };
};
