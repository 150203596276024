import { rgba } from "polished";
import { useWindowScroll } from "react-use";
import styled from "styled-components";
import { Z_MAX_BASE } from "../styles/z-index";
import tcc_logo from "../imgs/tcc-logo.png";

const Header = () => {
  const windowScroll = useWindowScroll();
  const scrollThreshold = 80;

  return (
    <HeaderContainer scrolledPastFeatured={windowScroll.y > scrollThreshold}>
      <Logo
        src={tcc_logo}
        alt="TCC"
        scrolledPastFeatured={windowScroll.y > scrollThreshold}
      />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  padding: 0 8vw;
  z-index: ${Z_MAX_BASE};
  overflow: hidden;
  background: ${({ scrolledPastFeatured }) =>
    scrolledPastFeatured ? rgba(0, 0, 0, 0.4) : ""};
  transition: background-color 200ms linear;
`;

const Logo = styled.img`
  padding: 12px 0 6px 0;
  filter: ${({ scrolledPastFeatured }) =>
    scrolledPastFeatured ? "invert(100%)" : "none"};
`;

export default Header;
