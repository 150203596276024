const base = 0;
const above = 1;

export const Z_MAX_BASE = 9999;

// EPG
export const Z_EPG_HOVER = above + base;
export const Z_EPG_LINE = above + Z_EPG_HOVER;
export const Z_EPG_HEADER = above + Z_EPG_LINE;
export const Z_EPG_TIMELINE = above + Z_EPG_HEADER;
export const Z_EPG_GO_NOW = above + Z_EPG_TIMELINE;
export const Z_EPG_CIRCLE = above + Z_EPG_TIMELINE;
export const Z_EPG_CIRCLE_BEFORE = above + Z_EPG_CIRCLE;
export const Z_EPG_TOOLTIP = above + Z_EPG_TIMELINE;
export const Z_EPG_DROPDOWN = above + Z_EPG_TIMELINE;

// Loading
export const Z_LOADING_CONTAINER = Z_MAX_BASE;
export const Z_LOADING_ROTATIVE = above + Z_MAX_BASE;
export const Z_CLOSE = above + Z_MAX_BASE;

// Featured
export const Z_FEATURED_BLUR = above + base;
export const Z_FEATURED_OVERLAY = above + Z_FEATURED_BLUR;
export const Z_FEATURED_INFO = above + Z_FEATURED_OVERLAY;

// Events
export const Z_EVENT_HOVER = above + base;
export const Z_EVENT_INFO = above + Z_EVENT_HOVER;
