import styled from "styled-components";
import scrollBarStyles from "../../styles/scrollBar";
import { theme } from "../../styles/theme";
import { Z_CLOSE } from "../../styles/z-index";

export const DetailContainer = styled.div`
  width: 70vw;
  height: 280px;

  .container {
    display: flex;
    column-gap: 20px;
    height: inherit;

    @media only screen and (max-width: 960px) {
      flex-direction: column;
      row-gap: 16px;
      overflow: auto;
      margin-top: 16px;
      ${scrollBarStyles};
      padding-right: 12px;
    }
  }

  .event-img {
    height: 280px;
    aspect-ratio: 4 / 3;
    object-fit: cover;

    @media only screen and (max-width: 640px) {
      width: 100%;
      height: auto;
    }
    @media only screen and (min-width: 640px) and (max-width: 960px) {
      width: 75%;
      height: auto;
    }
  }

  .close {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    z-index: ${Z_CLOSE};
  }

  @media only screen and (max-width: 960px) {
    height: 420px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DetailHeader = styled.div`
  display: flex;
  column-gap: 32px;
  align-items: center;
  position: sticky;
  top: 0;
  background: #ffffff;

  h1 {
    font-size: 24px;
    height: fit-content;
    margin: 0;
  }

  img {
    max-height: 48px;
    max-width: 67.75px;
  }
`;

export const DetailData = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  padding-right: 16px;
  height: 100%;

  @media only screen and (min-width: 960px) {
    overflow: auto;
    ${scrollBarStyles}
  }
`;

export const BottomContainer = styled.div`
  margin-top: 12px;
  display: flex;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    row-gap: 12px;
  }
`;

export const Info = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  svg {
    color: ${theme.colors.primary};
  }
  row-gap: 12px;

  .info-row {
    display: flex;
    column-gap: 8px;
  }

  .text {
    line-height: 16px;
  }
`;

export const NextEmissions = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    color: ${theme.colors.primary};
  }
  row-gap: 12px;

  .emission-header {
    display: flex;
    column-gap: 8px;
    cursor: pointer;
    user-select: none;
  }

  .emission-row {
    padding-left: 8px;
    display: flex;
    column-gap: 8px;
  }

  .text {
    line-height: 16px;
  }

  .circle-wrapper {
    line-height: 16px;
  }
`;
