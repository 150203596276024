import * as React from "react";
import { useLayer, useHover, Arrow } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import styled, { ThemeContext } from "styled-components";
import { Z_EPG_TOOLTIP } from "../../styles/z-index";

export const Tooltip = ({ children, text }) => {
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });
  const themeContext = React.useContext(ThemeContext);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: "top-center",
    triggerOffset: 8,
  });

  let trigger;
  if (isReactText(children)) {
    trigger = (
      <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  } else {
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  }

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <TooltipContainer
              className="tooltip-box"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              {text}
              <Arrow
                {...arrowProps}
                backgroundColor={themeContext.colors.primary}
                borderWidth={0}
                size={6}
              />
            </TooltipContainer>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

function isReactText(children) {
  return ["string", "number"].includes(typeof children);
}

const TooltipContainer = styled(motion.div)`
  background: ${({ theme }) => theme.colors.primary};
  color: #ffffff;
  padding: 4px;
  border-radius: 4px;
  text-shadow: none;
  font-weight: 500;
  z-index: ${Z_EPG_TOOLTIP};
`;
