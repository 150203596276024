import { useEffect, useState } from "react";
import formatRelative from "./formatRelative";

export const useEventMetadata = ({ event, metadatas, addMetadatas }) => {
  const [eventMetadata, setEventMetadata] = useState(null);
  useEffect(() => {
    const fetchEventMetadata = async () => {
      const existingMetadata = metadatas.find((m) => m.id === event.id);
      if (existingMetadata) {
        setEventMetadata(existingMetadata);
      } else {
        await fetch(
          `${process.env.REACT_APP_API_URL}/asset/${event.id}/?cable_operator=1&format=json`
        )
          .then((response) => response.json())
          .then((data) => {
            setEventMetadata(data);
            addMetadatas([data]);
          });
      }
    };

    fetchEventMetadata();
  }, [event, metadatas, addMetadatas]);

  return eventMetadata;
};

export const parseCast = ({ cast, maxActors }) => {
  let actors = "";
  let actorsQuantity = 0;
  let director = "";
  if (cast) {
    for (const cast_member of cast) {
      if (cast_member.role === "A") {
        if (!actors) {
          actors = cast_member.name;
          actorsQuantity++;
        } else if (!maxActors || actorsQuantity < maxActors) {
          actors = actors.concat(`/${cast_member.name}`);
          actorsQuantity++;
        }
      } else if (cast_member.role === "D" && !director) {
        director = cast_member.name;
      }
    }
  }
  return { actors, director };
};

const emissionPrecedence = (e1, e2) =>
  e1.startDate < e2.startDate ? -1 : e1.startDate > e2.startDate ? 1 : 0;

export const fetchNextEmissions = async ({ eventTitle, setNextEmissions }) => {
  const nextEmissions = [];
  await fetch(
    `${process.env.REACT_APP_API_URL}/asset_user_offer/?strict_search=${eventTitle}&content_types=LIV&cable_operator=1&format=json`
  )
    .then((response) => response.json())
    .then(async (events) => {
      for (const event of events.results.sort(emissionPrecedence)) {
        if (new Date(event.offer_start_date) > new Date()) {
          nextEmissions.push({
            startDate: formatRelative(
              new Date(event.offer_start_date),
              new Date()
            ),
            channel: event.video_provider?.name.replace("[Live] ", ""),
          });
        }
      }
    });
  setNextEmissions(nextEmissions);
};
