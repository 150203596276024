import React, { useMemo } from "react";
import { useMeasure } from "react-use";
import styled from "styled-components";
import Event from "./Event";

const VideoProviderEvents = ({
  window,
  lineWidth,
  onItemSelect,
  events,
  isAdult,
}) => {
  const [ref, { width }] = useMeasure();
  const filteredEvents = useMemo(
    () =>
      fillFakeEvents(
        events
          .sort(
            (ev1, ev2) =>
              new Date(ev1.emission_start).getTime() -
              new Date(ev2.emission_start).getTime()
          )
          .filter((event) => fitInWindows(event, window)),
        window
      ),
    [events, window]
  );

  return (
    <EventsContainer ref={ref} style={{ width: lineWidth }}>
      {filteredEvents.map((event) => {
        return (
          <Event
            {...scale(event, window)}
            id={event.id}
            event={event}
            key={`${event.id}`}
            onItemSelect={onItemSelect}
            parentDim={{ width }}
            isAdult={isAdult}
          />
        );
      })}
    </EventsContainer>
  );
};

const fitInWindows = (event, window) => {
  if (event) {
    const eventStart = new Date(event.emission_start).getTime();
    const eventEnd = new Date(event.emission_end).getTime();
    const windowStart = window.windowStart;
    const windowEnd = window.windowEnd;

    if (eventEnd > windowStart && windowEnd > eventStart) {
      return true;
    }
  }

  return false;
};

// Define el tamaño de los eventos que quedan dentro de la ventana
const scale = (event, window) => {
  let eventStart = new Date(event.emission_start).getTime();
  let eventEnd = new Date(event.emission_end).getTime();
  let fakeEventStart = eventStart;
  let fakeEventEnd = eventEnd;
  const windowStart = window.windowStart;
  const windowEnd = window.windowEnd;

  let noFitLeft = false;
  let noFitRight = false;
  if (fakeEventStart < windowStart) {
    fakeEventStart = windowStart;
    noFitLeft = true;
  }

  if (fakeEventEnd > windowEnd) {
    fakeEventEnd = windowEnd;
    noFitRight = true;
  }

  const windowEventSize = fakeEventEnd - fakeEventStart;
  const windowSize = windowEnd - windowStart;

  const width = `${(windowEventSize * 100) / windowSize}%`;

  const now = new Date().getTime();
  const active = eventStart < now && eventEnd >= now;
  const left = `${((fakeEventStart - windowStart) * 100) / windowSize}%`;
  return { width, left, noFitLeft, noFitRight, active };
};

// Rellena espacios sin información en la ventana
const fillFakeEvents = (events, window) => {
  const windowStart = window.windowStart;
  const windowEnd = window.windowEnd;
  let hour = windowStart;
  const newEvents = [];
  if (events.length === 0) {
    const newEvent = {
      id: `f${new Date(windowStart).getTime()}`,
      emission_start: new Date(windowStart),
      emission_end: new Date(windowEnd),
      original_title: "Sin Información",
      fake_event: true,
    };
    newEvents.push(newEvent);
    hour = windowEnd;
  }

  events.forEach((event) => {
    let newEventStart = 0;
    let newEventEnd = 0;
    let eventStart = new Date(event.emission_start).getTime();
    let eventEnd = new Date(event.emission_end).getTime();
    if (eventStart > hour) {
      newEventStart = hour;
      newEventEnd = eventStart;
    }
    if (newEventStart < newEventEnd) {
      const newEvent = {
        id: `f${new Date(newEventStart).getTime()}`,
        emission_start: new Date(newEventStart),
        emission_end: new Date(newEventEnd),
        original_title: "Sin Información",
        fake_event: true,
      };
      newEvents.push(newEvent);
    }

    newEvents.push(event);
    hour = eventEnd;
  });

  if (hour < windowEnd) {
    const newEvent = {
      id: `f${new Date(hour).getTime()}`,
      emission_start: hour,
      emission_end: windowEnd,
      original_title: "Sin Información",
      fake_event: true,
    };
    newEvents.push(newEvent);
  }
  return newEvents;
};

const EventsContainer = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

export default VideoProviderEvents;
