import FeaturedSlide from "./FeaturedSlide";
import Flickity from "react-flickity-component";
import { useEffect, useRef, useState } from "react";
import formatRelative from "../../utils/formatRelative";
import { fetchEventTime, fetchFeaturedData } from "../../utils/featuredUtils";
import { UPDATE_FEATURED_MS } from "../../configuration";

const Featured = ({ openDetail, setFetched, addMetadatas }) => {
  const flickityOptions = {
    draggable: true,
    pageDots: false,
    cellAlign: "left",
    initialIndex: 0,
    prevNextButtons: false,
    contain: true,
    autoPlay: 8000,
    selectedAttraction: 0.2,
    friction: 0.8,
    percentPosition: false,
  };

  const flkty = useRef();
  const [rawFeatured, setRawFeatured] = useState([]);
  const [featuredList, setFeaturedList] = useState([]);

  useEffect(() => {
    const fetchFeaturedEvents = async () => {
      const featuredData = await fetchFeaturedData();
      setRawFeatured(featuredData);
      addMetadatas(featuredData);
    };

    fetchFeaturedEvents();
  }, [addMetadatas]);

  useEffect(() => {
    const createSlides = async () => {
      const ret = [];
      for (const [idx, item] of rawFeatured.entries()) {
        const { emissionStart } = await fetchEventTime({
          event: item,
        });
        ret.push(
          <FeaturedSlide
            key={idx}
            index={idx}
            flktyRef={flkty.current}
            event={item}
            nextEmission={formatRelative(emissionStart, new Date())}
            openDetail={openDetail}
            numOfSlides={rawFeatured.length}
          />
        );
      }
      if (ret.length > 0) {
        setFeaturedList(ret);
        setFetched();
      }
    };
    createSlides();
    // Actualizo las slides cada 1 minuto, para que
    // la info de tiempo esté bien, y actualizar los
    // eventos cuando terminen.
    setInterval(createSlides, UPDATE_FEATURED_MS);
  }, [rawFeatured, openDetail, setFetched]);

  useEffect(() => {
    return () => {
      setRawFeatured([]);
      setFeaturedList([]);
    };
  }, []);

  useEffect(() => {
    if (rawFeatured.length > 0) {
      flkty.current.on("pointerUp", () => {
        flkty.current.player.play();
      });
    }
  }, [rawFeatured]);

  return rawFeatured.length > 0 ? (
    <Flickity
      options={flickityOptions}
      flickityRef={(c) => (flkty.current = c)}
    >
      {featuredList}
    </Flickity>
  ) : null;
};

export default Featured;
