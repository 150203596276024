import React, { useEffect } from "react";
import { useMeasure, usePrevious, useSlider } from "react-use";
import styled from "styled-components";
import {
  Z_EPG_GO_NOW,
  Z_EPG_TIMELINE,
  Z_EPG_CIRCLE,
  Z_EPG_CIRCLE_BEFORE,
} from "../../../styles/z-index";

import { Tooltip } from "../../Atoms/Tooltip";
import { addHours } from "../../../utils/epgUtils";

export const TimeLine = ({
  window: timeWindow,
  setWindow,
  setCantHours,
  setWidth,
  left,
  centerWindow,
}) => {
  const ref = React.useRef(null);
  const { isSliding, value } = useSlider(ref);
  const [containerRef, { width }] = useMeasure();
  const prevValue = usePrevious(value);

  // Actualizar ventana al arrastrar la linea de tiempo
  useEffect(() => {
    if (isSliding) {
      if (prevValue - value === 0) return;

      const slideRight = prevValue - value > 0;
      const windowStart = new Date(timeWindow.windowStart).setMinutes(
        new Date(timeWindow.windowStart).getMinutes() +
          (slideRight ? 1 : -1) * 30
      );
      const windowEnd = new Date(timeWindow.windowEnd).setMinutes(
        new Date(timeWindow.windowEnd).getMinutes() + (slideRight ? 1 : -1) * 30
      );
      setWindow({ windowStart: windowStart, windowEnd: windowEnd });
    }
  }, [value, setWindow, timeWindow, isSliding, prevValue]);

  // Define la cantidad de horas que entran dependiendo del tamaño de la ventana
  useEffect(() => {
    setCantHours(Math.floor(width / 200));
    setWidth(width);
  }, [width, setCantHours, setWidth]);

  // Devuelve las horas de la ventana
  const hours = () => {
    const { windowStart, windowEnd } = timeWindow;
    const hours = [];

    let hour = addHours(new Date(windowStart), 0.25);
    while (hour <= windowEnd) {
      hours.push(new Date(hour));
      hour.setMinutes(hour.getMinutes() + 30);
    }

    return hours;
  };

  return (
    <TimeLineContainer ref={ref}>
      {(left < 0 || left > 100) && (
        <GoNow
          left={left < 0}
          right={left > 100}
          onClick={centerWindow}
          width={width}
        >
          {width > 200 ? "Ahora" : ""}
        </GoNow>
      )}
      <Line window={timeWindow} left={left} />
      <div ref={containerRef}>
        {hours().map((hour, idx) => (
          <span key={idx}>
            {hour.getHours()}:{hour.getMinutes() < 10 && 0}
            {hour.getMinutes()}
          </span>
        ))}
      </div>
    </TimeLineContainer>
  );
};

const Line = ({ height, left }) => {
  return left < 0 || left > 100 ? null : (
    <Tooltip
      text={`${new Date().getHours()}:${
        new Date().getMinutes() < 10 ? 0 : ""
      }${new Date().getMinutes()}`}
    >
      <Circle style={{ left: `${left}%` }} height={height} />
    </Tooltip>
  );
};

const GoNow = styled.div`
  position: absolute;
  left: ${({ left }) => (left ? "15px" : "auto")};
  right: ${({ right }) => (right ? "15px" : "auto")};
  background: ${({ width }) => (width > 200 ? "#f00" : "")};
  padding: 0px 16px;
  height: 24px;
  z-index: ${Z_EPG_GO_NOW};
  text-shadow: none;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;

  &:before {
    content: "";
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #f00;
    transform: rotate(${({ left }) => (left ? "270deg" : "90deg")});
    top: 6px;
    left: ${({ left }) => (left ? "-18px" : "auto")};
    right: ${({ right }) => (right ? "-18px" : "auto")};
  }
`;

const TimeLineContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: move;
  position: sticky;
  z-index: ${Z_EPG_TIMELINE};
  & div {
    display: flex;
    flex: 1;
    & span {
      flex: 1;
      text-shadow: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      position: relative;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Circle = styled.div`
  position: absolute;
  margin-left: -8px;
  bottom: -8px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.lightPrimary};
  z-index: ${Z_EPG_CIRCLE};
  cursor: default;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    height: 10px;
    width: 2px;
    margin-left: 5px;
    margin-top: 14px;
    background: ${({ theme }) => theme.colors.lightPrimary};
    z-index: ${Z_EPG_CIRCLE_BEFORE};
  }
`;
