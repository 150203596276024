import { useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import imageDefault from "../../../imgs/img_default_hz.png";
import formatRelative from "../../../utils/formatRelative";
import timeInformation from "../../../utils/timeInformation";
import { InfoIcon } from "../../Atoms/Icon/Icon";
import ProgressBar from "../../Atoms/ProgressBar";
import { Info, ItemContainer, ItemHover } from "./styles";

const Item = ({ data, onItemSelect }) => {
  const [hover, setHover] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const imgControls = useAnimation();

  const timeInfo = timeInformation(
    new Date(data.offer_emission_start),
    new Date(data.offer_emission_end),
    new Date()
  );

  useEffect(() => {
    if (imgLoaded) {
      imgControls.start({ opacity: 1 });
    }
  }, [imgLoaded, imgControls]);

  return (
    <ItemContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onItemSelect(data)}
      initial={{ opacity: 0 }}
      animate={imgControls}
    >
      {hover && (
        <ItemHover
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        />
      )}
      <img
        className="event-image"
        src={data.images_processed["FRM"]?.file || imageDefault}
        alt={data.localized[0]?.title || data.original_title}
        onLoad={() => setImgLoaded(true)}
      />
      <img
        className="provider-image"
        src={data.video_provider.images_processed["NET"]?.file}
        alt={data.video_provider.name}
      />
      <Info>
        <div className="top-row">
          <div className="text">
            <h3>{data.localized[0]?.title || data.original_title}</h3>
            <p>
              {formatRelative(new Date(data.offer_emission_start), new Date())}
            </p>
          </div>
          <InfoIcon
            circle={true}
            width={16}
            height={16}
            fill="#ffffff"
            bgColor="transparent"
            border="2px solid #fff"
          />
        </div>
        <ProgressBar progress={timeInfo?.elapsedTimePercentage} flat />
      </Info>
    </ItemContainer>
  );
};

export default Item;
