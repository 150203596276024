import { useRef } from "react";
import Flickity from "react-flickity-component";
import Item from "./Item";
import { Container, EventsContainer } from "./styles";
import Loading from "../../Atoms/Loading/Loading";

const SearchedEvents = ({ events, search, onItemSelect }) => {
  const flickity = useRef();
  const flickityOptions = {
    draggable: true,
    pageDots: false,
    cellAlign: "left",
    initialIndex: 0,
    prevNextButtons: true,
    contain: true,
    autoPlay: false,
    selectedAttraction: 0.2,
    friction: 0.8,
    percentPosition: false,
    groupCells: true,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 65,
      y2: 45,
      x3: 20,
    },
  };

  return search.length > 0 ? (
    <Container>
      <h2>Eventos</h2>
      {events ? (
        events.length > 0 ? (
          <EventsContainer
            prevEnabled={flickity.current?.prevButton.isEnabled}
            nextEnabled={flickity.current?.nextButton.isEnabled}
          >
            <Flickity
              options={flickityOptions}
              flickityRef={(ref) => (flickity.current = ref)}
            >
              {events.map((ev) => (
                <Item key={ev.id} data={ev} onItemSelect={onItemSelect} />
              ))}
            </Flickity>
          </EventsContainer>
        ) : (
          <h3 className="no-results-found">No se encontraron resultados.</h3>
        )
      ) : (
        <Loading style={{ position: "relative", margin: "8px 0" }} />
      )}
    </Container>
  ) : null;
};

export default SearchedEvents;
