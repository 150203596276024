import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ArrowDownSmallIcon } from "./Icon/Icon";
import Dropdown from "./Dropdown";
import Button from "./Button";
import scrollBarStyles from "../../styles/scrollBar";
import { motion } from "framer-motion";
import { Z_EPG_DROPDOWN } from "../../styles/z-index";
import { theme } from "../../styles/theme";
import { lighten } from "polished";

const ULContainer = styled(motion.ul)`
  background: ${({ theme }) => theme.colors.highContrast};
  margin: 0;
  padding: 0.5rem;
  list-style-type: none;
  box-sizing: border-box;
  z-index: ${Z_EPG_DROPDOWN};
  border-radius: auto;
  min-width: auto;

  & div {
    max-height: 150px;
    overflow-y: auto;
    height: 100%;
    ${scrollBarStyles}
  }

  & li {
    text-shadow: none;
    transition: color 200ms ease;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const ButtonDropdown = ({ buttonLabel, items, faIcon, text, narrow }) => {
  const [isOpen, setOpen] = useState(false);
  const buttonRef = useRef();
  const [triggerProps, setTriggerProps] = useState({});
  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <div ref={buttonRef}>
        <Button
          text={text}
          narrow={narrow}
          faIcon={faIcon}
          {...triggerProps}
          onClick={() => setOpen(!isOpen)}
          style={{
            color: "#ffffff",
            background: lighten(0.05, theme.colors.events),
            borderRadius: "16px",
          }}
        >
          <span>{buttonLabel}</span>
          <ArrowDownSmallIcon fill="#ffffff" />
        </Button>
      </div>
      <Dropdown
        isOpen={isOpen}
        closeTrigger={close}
        setTriggerProps={setTriggerProps}
        Container={ULContainer}
      >
        {items}
      </Dropdown>
    </>
  );
};

export default ButtonDropdown;
