import styled from "styled-components";
import {
  Z_FEATURED_OVERLAY,
  Z_FEATURED_BLUR,
  Z_FEATURED_INFO,
} from "../../styles/z-index";
import { motion } from "framer-motion";

export const FeaturedContainer = styled.div`
  width: 100vw;
  height: 39.38vw;
  display: flex;
  padding: 100px 10vw 30px 10vw;
  flex-direction: column;
  margin: 0;
  background-color: #000000;

  @media only screen and (max-width: 1280px) {
    height: 506.25px;
    &:before {
      height: 506.25px;
    }
  }

  @media only screen and (max-width: 960px) {
    height: 440px;
    &:before {
      margin: -100px -10vw -30px -10vw;
      position: absolute;
      content: "";
      width: 100vw;
      height: 440px;
      background: rgba(0, 0, 0, 0.5);
      z-index: ${Z_FEATURED_OVERLAY};
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 40vw;
    z-index: ${Z_FEATURED_INFO};

    @media only screen and (max-width: 1280px) {
      max-width: 50vw;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      align-items: center;
      text-align: center;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 40vw;
    z-index: ${Z_FEATURED_INFO};

    @media only screen and (max-width: 1280px) {
      max-width: 50vw;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      align-items: center;
      text-align: center;
    }
  }
`;

export const MainImage = styled(motion.div)`
  box-sizing: border-box;
  position: absolute;
  width: 70vw;
  aspect-ratio: 16 / 9;
  right: 0px;
  overflow: hidden;
  top: 0;

  img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  @media only screen and (max-width: 1280px) {
    width: 900px;
    height: 506.25px;
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    height: 440px;
  }

  @media only screen and (min-width: 768px) {
    &:before {
      content: "";
      position: absolute;
      width: 30%;
      height: 100%;
      background: rgb(41, 47, 54);
      background: linear-gradient(
        270deg,
        rgba(41, 47, 54, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      z-index: ${Z_FEATURED_BLUR};
    }
  }
`;

export const TitleContainer = styled.div`
  margin: 0;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h1`
  margin: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 5px;
  text-transform: uppercase;
  height: fit-content;
  max-height: 80px;
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProviderLogo = styled.img`
  width: 80px;
  padding: 0;
`;

export const NextEmission = styled.h2`
  margin: 16px 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
`;

export const Separator = styled.hr`
  width: 100%;
  background-color: #ffffff;
  height: 1px;
  margin: 30px auto;
`;
