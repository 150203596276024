import "./App.css";
import Header from "./components/Header";
import Featured from "./components/Featured/Featured";
import Detail from "./components/Detail/Detail";
import { useCallback, useState } from "react";
import { Theme } from "./styles/theme";
import EPG from "./components/EPG/EPG";
import ReactModal from "react-modal";
import { modalStyles } from "./styles/modalStyles";
import Loading from "./components/Atoms/Loading/Loading";

function App() {
  const [detailEvent, setDetailEvent] = useState(null);
  const [fetchedFeatured, setFetchedFeatured] = useState(false);
  const [fetchedEvents, setFetchedEvents] = useState(false);
  const [metadatas, setMetadatas] = useState([]);
  ReactModal.setAppElement("#root");

  const addMetadatas = useCallback((titleMetadatas) => {
    setMetadatas((prevMetadatas) => {
      let newMetadatas = [];
      for (const titleMetadata of titleMetadatas) {
        if (!prevMetadatas.find((m) => m.id === titleMetadata.id)) {
          newMetadatas.push(titleMetadata);
        }
      }
      return prevMetadatas.concat(newMetadatas);
    });
  }, []);

  const openDetail = useCallback((event) => {
    setDetailEvent(event);
  }, []);

  const handleFetchedFeatured = useCallback(() => setFetchedFeatured(true), []);

  return (
    <div
      className="App"
      style={
        !fetchedFeatured || !fetchedEvents
          ? { overflow: "hidden", height: "100vh" }
          : {}
      }
    >
      <Theme>
        <Header />
        {(!fetchedFeatured || !fetchedEvents) && <Loading fullscreen />}
        <ReactModal
          isOpen={!!detailEvent}
          style={modalStyles}
          onRequestClose={() => setDetailEvent(null)}
          shouldReturnFocusAfterClose={false}
        >
          {detailEvent && (
            <Detail
              event={detailEvent}
              metadatas={metadatas}
              addMetadatas={addMetadatas}
              onClose={() => setDetailEvent(null)}
            />
          )}
        </ReactModal>
        <Featured
          openDetail={openDetail}
          setFetched={handleFetchedFeatured}
          addMetadatas={addMetadatas}
        />
        <EPG
          openDetail={(event) => setDetailEvent(event)}
          setFetchedEvents={() => setFetchedEvents(true)}
        />
      </Theme>
    </div>
  );
}

export default App;
