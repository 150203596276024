import {
  MILLISECONDS_IN_A_SECOND,
  MINS_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
} from "./tvProvidersGridConstants";
import { Duration, DateTime } from "luxon";
import { CHANNEL_LIST_FILTER, EPG_CACHE_HOURS } from "../configuration";

export const addHours = (date, hours) => {
  const millisecondsInAnHour =
    MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINS_IN_AN_HOUR;
  return new Date(date.getTime() + hours * millisecondsInAnHour);
};

export const selectVideoProviderById = ({ videoProviders, id }) =>
  videoProviders.find((videoProvider) => videoProvider.service_id === id);

const findFirstBlockEmissionStart = (utcDateFrom, blockSizeDuration) => {
  let start = utcDateFrom.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  while (start.plus(blockSizeDuration) < utcDateFrom) {
    start = start.plus(blockSizeDuration);
  }

  return start;
};

const findLastBlockEmissionEnd = (utcDateTo, blockSizeDuration) => {
  let end = utcDateTo
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .plus({ days: 1 });

  while (end.minus(blockSizeDuration) > utcDateTo) {
    end = end.minus(blockSizeDuration);
  }

  return end;
};

const tvProviderPrecedence = (a, b) =>
  a.service_id < b.service_id ? -1 : a.service_id > b.service_id ? 1 : 0;

export const fetchEPGData = ({
  dateFrom,
  dateTo,
  fetchedBlocks,
  setFetchedBlocks,
  setEvents,
  setVideoProvidersData,
  setVideoProvidersIds,
}) => {
  const blockSizeDuration = Duration.fromObject({ hours: EPG_CACHE_HOURS });

  const utcDateFrom = dateFrom
    ? DateTime.fromJSDate(dateFrom).toUTC()
    : DateTime.utc();
  const utcDateTo = dateTo
    ? DateTime.fromJSDate(dateTo).toUTC()
    : DateTime.utc();

  const firstBlockEmissionStart = findFirstBlockEmissionStart(
    utcDateFrom,
    blockSizeDuration
  );
  const lastBlockEmissionEnd = findLastBlockEmissionEnd(
    utcDateTo,
    blockSizeDuration
  );

  let emissionStart = firstBlockEmissionStart;
  while (emissionStart < lastBlockEmissionEnd) {
    const emissionEnd = emissionStart.plus(blockSizeDuration);
    const emissionStartISO = emissionStart.toISO({
      suppressMilliseconds: true,
    });
    const emissionEndISO = emissionEnd.toISO({ suppressMilliseconds: true });

    const isBlockFetched = fetchedBlocks?.includes(emissionStartISO);
    if (!isBlockFetched) {
      fetchEPGBlock({
        emissionStart: emissionStartISO,
        emissionEnd: emissionEndISO,
        setEvents,
        setVideoProvidersData,
        setVideoProvidersIds,
      });
      setFetchedBlocks((prevFetchedBlocks) => [
        ...prevFetchedBlocks,
        emissionStartISO,
      ]);
    }
    emissionStart = emissionEnd;
  }
};

const fetchEPGBlock = async ({
  emissionStart,
  emissionEnd,
  setEvents,
  setVideoProvidersData,
  setVideoProvidersIds,
}) => {
  await fetch(
    `${process.env.REACT_APP_API_URL}/navigation_filter/${CHANNEL_LIST_FILTER}/filter/?cable_operator=1&emission_start=${emissionStart}&emission_end=${emissionEnd}&format=json`
  )
    .then((response) => response.json())
    .then((data) => {
      setEvents((prevEvents) => {
        let updatedEvents = { ...prevEvents };
        for (const tvProvider of data.results) {
          if (updatedEvents[tvProvider.service_id]) {
            for (const event of tvProvider.events) {
              if (
                !updatedEvents[tvProvider.service_id].find(
                  (e) => e.id === event.id
                )
              ) {
                updatedEvents[tvProvider.service_id].push(event);
              }
            }
          } else {
            updatedEvents[tvProvider.service_id] = tvProvider.events;
          }
        }
        return updatedEvents;
      });
      const tvProviders = data.results.sort(tvProviderPrecedence);
      const ids = tvProviders.map((tvProvider) => tvProvider.service_id);
      setVideoProvidersData((prevProvidersData) =>
        prevProvidersData.length === 0 ? tvProviders : prevProvidersData
      );
      setVideoProvidersIds((prevIds) => (prevIds.length === 0 ? ids : prevIds));
    });
};

export const fetchMatchingEvents = async ({
  search,
  signal,
  setSearchedEvents,
}) => {
  await fetch(
    `${process.env.REACT_APP_API_URL}/asset_user_offer/?search=${search}&content_types=LIV&cable_operator=1&format=json`,
    { signal }
  )
    .then((response) => response.json())
    .then(async (events) => {
      setSearchedEvents(events.results);
    })
    .catch((e) => {
      if (e.name === "AbortError") {
        console.error(e, "Reason: ".concat(signal.reason));
      }
    });
};
