import styled from "styled-components";
import { ReactComponent as Search } from "./icons/ico_search.svg";
import { ReactComponent as ArrowDownSmall } from "./icons/ico_arrow_down_small.svg";
import { ReactComponent as ArrowDown } from "./icons/ico_arrow_down.svg";
import { ReactComponent as ArrowLeft } from "./icons/ico_arrow_left.svg";
import { ReactComponent as ArrowRight } from "./icons/ico_arrow_right.svg";
import { ReactComponent as Close } from "./icons/ico_close.svg";
import { ReactComponent as Clock } from "./icons/ico_clock.svg";
import { ReactComponent as Info } from "./icons/ico_info.svg";

const IContainer = styled.i`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  transition: all ease 200ms;
  box-sizing: content-box;

  ${(props) =>
    props.circle &&
    `background-color: ${
      props.bgColor ? props.bgColor : props.theme.colors.highContrast
    }`};
  ${(props) => props.circle && "border-radius: 50%"};

  ${(props) => props.circle && `border: ${props.border ? props.border : null}`};
  & svg {
    fill: ${({ fill, theme, circle }) =>
      fill
        ? fill
        : !circle
        ? theme.colors.highContrast
        : theme.colors.lowContrast};
    fill-opacity: ${({ opacity }) => opacity || 1};
  }

  & svg circle {
    fill: ${({ fill, theme, read }) =>
      fill ? fill : read ? theme.colors.primary : theme.colors.highContrast};
  }
`;

const Icon = ({
  fill,
  children,
  width,
  height,
  circle,
  read,
  bgColor,
  border,
  opacity,
}) => {
  return (
    <IContainer
      fill={fill}
      circle={circle}
      width={width ? width : 24}
      height={height}
      read={read}
      bgColor={bgColor}
      border={border}
      opacity={opacity}
    >
      {children}
    </IContainer>
  );
};

export const SearchIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <Search width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowDownSmallIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <ArrowDownSmall width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowDownIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <ArrowDown width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowLeftIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <ArrowLeft width={width ? width : 24} height={height} />
  </Icon>
);

export const ArrowRightIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <ArrowRight width={width ? width : 24} height={height} />
  </Icon>
);

export const CloseIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <Close width={width ? width : 24} height={height} />
  </Icon>
);

export const ClockIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
  >
    <Clock width={width ? width : 24} height={height} />
  </Icon>
);

export const InfoIcon = ({
  fill,
  width,
  height,
  circle,
  bgColor,
  opacity,
  border,
}) => (
  <Icon
    fill={fill}
    circle={circle}
    width={width ? width : 24}
    height={height ? height : 24}
    bgColor={bgColor}
    opacity={opacity}
    border={border}
  >
    <Info width={width ? width : 24} height={height} />
  </Icon>
);
