import React from "react";
import {
  DAYS_WEEK,
  HOURS_IN_A_DAY,
  MAX_COUNT_DAYS_BACKWARD,
  MAX_COUNT_DAYS_FORWARD,
  MILLISECONDS_IN_A_SECOND,
  MINS_IN_AN_HOUR,
  MONTHS,
  SECONDS_IN_A_MINUTE,
} from "../../../utils/tvProvidersGridConstants";
import ButtonDropdown from "../../Atoms/ButtonDropdown";

const DropdownDays = ({ onDayClick, currentDay }) => {
  const dayNames = () => {
    const names = [];

    for (let i = -MAX_COUNT_DAYS_BACKWARD; i <= MAX_COUNT_DAYS_FORWARD; i++) {
      const day = new Date();
      day.setDate(day.getDate() + i);

      let name = `${DAYS_WEEK[day.getDay()]}, ${day.getDate()} ${
        MONTHS[day.getMonth()]
      }.`;

      switch (i) {
        case -1:
          name = "Ayer";
          break;
        case 0:
          name = "Hoy";
          break;
        case 1:
          name = "Mañana";
          break;
        default:
          break;
      }

      names.push(name);
    }

    return names;
  };

  const currentDayIndex = (now) => {
    const MILLISECONDS_IN_A_DAY =
      MILLISECONDS_IN_A_SECOND *
      SECONDS_IN_A_MINUTE *
      MINS_IN_AN_HOUR *
      HOURS_IN_A_DAY;

    const myCurrentDay = new Date(now);
    myCurrentDay.setHours(0, 0, 0, 0);

    const firstDay = new Date(
      new Date().getTime() - MAX_COUNT_DAYS_BACKWARD * MILLISECONDS_IN_A_DAY
    );
    firstDay.setHours(0, 0, 0, 0);

    return (
      (myCurrentDay.getTime() - firstDay.getTime()) / MILLISECONDS_IN_A_DAY
    );
  };

  const handleDayClick = (idx) => {
    onDayClick(idx);
  };

  const currentDayName = dayNames()[currentDayIndex(currentDay)];

  return (
    <ButtonDropdown
      buttonLabel={currentDayName}
      text
      items={dayNames().map((dayName, idx) => (
        <li
          key={idx}
          onClick={() => {
            handleDayClick(idx);
          }}
        >
          {dayName}
        </li>
      ))}
    />
  );
};

export { DropdownDays };
