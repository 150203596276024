import React from "react";
import styled from "styled-components";
import VideoProviderEvents from "./VideoProviderEvents/VideoProviderEvents";
import VideoProviderLogo from "./VideoProviderEvents/VideoProviderLogo";

const VideoProviderRow = ({
  window,
  lineWidth,
  style,
  videoProvider,
  events,
  onItemSelect,
}) => {
  return (
    <>
      {videoProvider ? (
        <VideoProviderRowContainer style={{ ...style }}>
          <VideoProviderLogo videoProvider={videoProvider} />
          {events ? (
            <VideoProviderEvents
              events={events}
              window={window}
              lineWidth={lineWidth}
              onItemSelect={onItemSelect}
              isAdult={videoProvider.is_adult}
            />
          ) : null}
        </VideoProviderRowContainer>
      ) : null}
    </>
  );
};

const VideoProviderRowContainer = styled.div`
  display: flex;
  height: 52px;
  margin-top: 2px;
`;

export default React.memo(VideoProviderRow);
