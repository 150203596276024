import React from "react";
import styled from "styled-components";
import {
  CHANNEL_1,
  CHANNEL_2,
  getImageWithoutThumb,
} from "../../../utils/thumbnailer";

const VideoProviderLogo = ({ videoProvider }) => {
  return (
    <Wrapper>
      <div className="logo">
        <img
          src={
            getImageWithoutThumb(videoProvider, CHANNEL_2) ||
            getImageWithoutThumb(videoProvider, CHANNEL_1)
          }
          alt={videoProvider?.name || ""}
          style={{
            filter: videoProvider?.service_id === 662 ? "invert(1)" : "",
          }}
        />
      </div>
      <div className="channel">{videoProvider?.service_id}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  & .logo {
    flex: 6;
    padding: 2px 0px;
    box-sizing: border-box;
    text-align: center;
    & img {
      max-height: 48px;
      max-width: 67.75px;
    }
  }
  & .channel {
    padding: 0 8px 0 4px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 3;
  }

  @media only screen and (max-width: 768px) {
    min-width: 102px;
  }
`;

export default React.memo(VideoProviderLogo);
