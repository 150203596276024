import { lighten } from "polished";
import { css } from "styled-components";
import { theme } from "./theme";

const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${lighten(0.2, theme.colors.primary)};
    border: 0 none ${lighten(0.2, theme.colors.primary)};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${lighten(0.2, theme.colors.primary)};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.lowContrast};
    border: 0 none ${lighten(0.2, theme.colors.primary)};
    border-radius: 9px;
  }

  &::-webkit-scrollbar-track:hover {
    background: ${(props) => props.theme.colors.lowContrast};
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export default scrollBarStyles;
