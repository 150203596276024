import Button from "../Atoms/Button";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import {
  FeaturedContainer,
  MainImage,
  NextEmission,
  ProviderLogo,
  Separator,
  Title,
  TitleContainer,
} from "./styles";
import { forceHttps } from "../../utils/misc";
import {
  FEATURED,
  FRAME,
  getImage,
  TOP_LOCATION,
} from "../../utils/thumbnailer";
import imageDefault from "../../imgs/img_default_hz.png";

const FeaturedSlide = ({
  index,
  flktyRef,
  event,
  nextEmission,
  openDetail,
  numOfSlides,
}) => {
  const controls = useAnimation();
  const buttonControls = useAnimation();
  const imageControls = useAnimation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const slideRef = useRef();

  const providerImg =
    event.video_providers[0].images_processed["NET"]?.file ||
    event.video_providers[0].images_processed["NT2"]?.file ||
    "";

  useEffect(() => {
    if (imageLoaded) {
      imageControls.start({ opacity: 1, transition: { duration: 1.5 } });
    }
  }, [imageLoaded, imageControls]);

  useEffect(() => {
    const onSelect = (selectedIdx) => {
      if (selectedIdx === index) {
        if (imageLoaded) {
          controls.start(() => {
            return {
              opacity: 1,
              y: 0,
              transition: { delay: 0.2, duration: 0.8 },
            };
          });
          buttonControls.start(() => {
            return {
              opacity: 1,
              y: 0,
              // Como el botón entra después que el resto de los componentes,
              // su delay de animación es delay + duration del resto
              transition: { delay: 1, duration: 0.8 },
            };
          });
        }
      } else if (
        index === selectedIdx - 1 ||
        (selectedIdx === 0 && index === numOfSlides - 1)
      ) {
        controls.start(() => ({
          opacity: 0,
          y: 30,
          transition: { delay: 0, duration: 1 },
        }));
        buttonControls.start(() => ({
          opacity: 0,
          y: 30,
          transition: { delay: 0, duration: 1 },
        }));
      }
    };

    flktyRef.on("select", onSelect);
  }, [flktyRef, index, controls, buttonControls, imageLoaded, numOfSlides]);

  useEffect(() => {
    return () => {
      setImageLoaded(false);
    };
  }, []);

  return (
    <FeaturedContainer ref={slideRef}>
      <MainImage initial={{ opacity: 0 }} animate={imageControls}>
        <motion.img
          src={getImage(event, FRAME, TOP_LOCATION, FEATURED) || imageDefault}
          onLoad={() => setImageLoaded(true)}
        />
      </MainImage>
      <div className="info-container">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={controls}
          className="info-container"
        >
          <TitleContainer>
            <Title>{event.localized[0]?.title || event.original_title}</Title>
          </TitleContainer>

          <NextEmission>{nextEmission}</NextEmission>
          {providerImg && (
            <ProviderLogo
              src={forceHttps(providerImg)}
              alt={event.video_providers[0].localized[0]?.title || ""}
            />
          )}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={buttonControls}
          className="button-container"
        >
          <Separator />
          <Button onClick={() => openDetail(event)} featured>
            Ver más
          </Button>
        </motion.div>
      </div>
    </FeaturedContainer>
  );
};

export default FeaturedSlide;
